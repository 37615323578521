import { FunctionComponent } from 'react';
import TopMenu from '../shared/top-menu/top-menu';
import Footer from '../shared/footer/footer';
import './about.scss';

export type Props = {};

const About: FunctionComponent<Props> = (props) => {
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page about">
      <TopMenu selected="about"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>We are SurveyTester</h1>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content">
          <p>
            Behind SurveyTester is a team of passionate professionals, each an expert in their
            field. Our collaborative spirit and commitment to excellence drive us to constantly
            improve and innovate. We pride ourselves on our customer-centric approach, working
            closely with clients to understand their unique challenges and deliver tailored
            solutions.
          </p>
          <p>
            Our team is our greatest asset. Each member brings a wealth of experience and a unique
            perspective, contributing to a collaborative environment where creativity thrives. From
            our developers to our customer support specialists, we are committed to delivering
            excellence in every aspect of our service.
          </p>
          <br />
          <br />
          <div className="team-mates">
            <div className="team-mate">
              <img src="/people/bernhard.jpg" alt="Bernhard Witt" />
              <div className="name">Bernhard Witt</div>
              <div className="title">Founder & CEO</div>
            </div>
            <div className="team-mate">
              <img src="/people/laura.jpg" alt="Laura Blacklock" />
              <div className="name">Laura Blacklock</div>
              <div className="title">Business Development (USA)</div>
            </div>
            <div className="team-mate">
              <img src="/people/ester.jpg" alt="Ester Mittermeier" />
              <div className="name">Ester Mittermeier</div>
              <div className="title">Sales & Marketing</div>
            </div>
            <div className="team-mate">
              <img src="/people/jim.jpg" alt="James Sugrue" />
              <div className="name">James Sugrue</div>
              <div className="title">Support & Documentation</div>
            </div>
            <div className="team-mate">
              <img src="/people/todd.jpg" alt="Todd Sandelman" />
              <div className="name">Todd Sandelman</div>
              <div className="title">Development & Support</div>
            </div>
            <div className="team-mate">
              <img src="/people/anne.jpg" alt="Anne Witt" />
              <div className="name">Anne Witt</div>
              <div className="title">Marketing</div>
            </div>

            <div className="team-mate">
              <img src="/people/surveybot.jpg" alt="SurveyBot" style={{ width: 'unset' }} />
              <div className="name">Survey Bot</div>
              <div className="title">QA Professional</div>
            </div>
          </div>
        </div>
      </div>

      <div className="page-content bg-2">
        <div className="inner-content">
          <h2>The Origin and Evolution of SurveyTester</h2>
          <p>
            When the world needs a solution for a problem, it often happens that multiple people
            have similar ideas and want to create a solution. This was also true for SurveyTester.
            The initial products were independently created by Bernhard Witt, and Jim Sugrue.
            Fortunately, they decided to collaborate to avoid duplicating efforts for the same
            problem. As a result, Bernhard's team continued the development. In the early years,
            SurveyTester was re-developed multiple times. Today, it is a modern application
            utilizing the React framework for the front end and a C# backend with SQL Server and
            other technologies.
          </p>
          <p>
            While Jim and his team developed their software to meet a client's needs, Bernhard was
            driven by intrinsic motivation. After founding his company, he worked on many scripting
            projects for clients and found the testing phase frustrating due to the massive amount
            of emails exchanged globally. His frustration with the survey QA process led him to
            program the first version of SurveyTester. As mentioned, the product has been
            re-developed multiple times over the years. The latest version is significantly
            different from the initial versions, adapting to evolving technology and security
            requirements, as well as changes in integrating with various survey systems.
          </p>
        </div>
      </div>
      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>Get in Touch</h2>
          <p>
            Ready to transform your survey QA process? Join us on our journey and experience the
            benefits of SurveyTester. Contact us today or start your free trial to see our premium
            features in action.
          </p>
          <div className="free-trial">
            <div
              className="free-trial-button"
              onClick={(e) => {
                //e.preventDefault();
                //history('/freetrial');
              }}
            >
              <a href="/freetrial">
                <span>Free Trial</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default About;
