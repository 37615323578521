import { FunctionComponent } from 'react';
import './14-day-trial.scss';
import Footer from '../shared/footer/footer';
import TopMenu from '../shared/top-menu/top-menu';
import ContactForm from '../shared/contactform/contact-form';

export type Props = {};

const Trial: FunctionComponent<Props> = (props) => {
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  // ----------------------------------------------------------------
  return (
    <div className="page contact">
      <TopMenu selected="freetrial"></TopMenu>
      <div className="page-content bg-top">
        <div className="inner-content">
          <h1>Choose Your Next Step</h1>
          <p>
            At SurveyTester, we understand that each business has unique needs and timelines.
            <br />
            That's why we offer two flexible paths to get you started
          </p>
          <p>
            Choose the path that best suits your needs and take the first step towards optimizing
            your survey quality assurance with SurveyTester.
          </p>
        </div>
      </div>

      <div className="page-content bg-1">
        <div className="inner-content">
          <h2>1. Start Your Free Trial</h2>
          <p>
            Dive into SurveyTester with ease by starting a free trial using one of our demo surveys.
            This option allows you to explore the system, test its features, and see firsthand how
            it can enhance your survey quality assurance process. Experience the full functionality
            of SurveyTester risk-free and at your own pace. Use the button below to start your free
            trial today.
          </p>
          <br />
          <div className="free-trial">
            <a
              href="https://free-trial.surveytester.com/try-for-free?r=www.surveytester.com"
              target="_blank"
              rel="noreferrer"
              className="free-trial-button"
            >
              <span>Start Free Trial</span>
            </a>
          </div>
          <br />
          <br />
          <br />
          <h2>2. Let's work together on Your Real Project</h2>
          <p>
            If you prefer a more hands-on approach, let's collaborate on your real project. By
            choosing this option, you'll be paired with one of our expert consultants who will guide
            you through the entire process. This ensures a seamless integration, eliminating the
            learning curve and allowing you to focus on your core objectives while we handle the
            technical details.
          </p>
          <p>Contact us by email or use the following form to tell us about your project needs.</p>
          <br />

          <ContactForm
            source="www.surveytester.com/freetrial"
            textfieldLabel="Project Details"
            textfieldPlaceholder="Describe your project and QA needs"
          />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Trial;
